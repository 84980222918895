import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/react-hooks';
import { Box, Container, makeStyles, Menu, MenuItem } from '@material-ui/core';
import {
  ArrowDropDown,
  ArrowDropUp,
  CloudDownload,
  CloudUpload,
  Delete,
  Sort,
  ZoomOutMap,
} from '@material-ui/icons';

import { isEmpty } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { SectionContainer } from '../Section/styled/StyledSection';
import Loading from '../Common/Loading';
import { GET_HOSPITAL_FILES_TO_LIST } from '../../graphql/queries';
import { SectionBar } from '../../componentsUI/SectionBar';
import TableCollapse from '../../componentsUI/tableCollapse';
import { KBytesPrettify } from '../../utils/BytesPrettifyUtils';
import { getUrlExtension, imageExtensions } from '../../utils/FileUtils';
import { DeleteFileDialog } from './DeleteFileDialog';
import { UploadFileDialog } from './UploadFileDialog';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { NoFilesWrapper } from '../Common/FileCard/styled/NoFilesWrapper';
import { Image } from '../Image/Image';
import { networkErrorParse } from "../../utils/ErrorGraphQLUtils";
import {AlertUI, AlertWrapperUI} from "../../componentsUI/Alert";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 2em 1em',
  },
  image: {
    maxHeight: 32,
  },
  subtitle: {
    margin: '.25em .375em 0',
    fontSize: '0.75em',
    color: theme.palette.grey.A200,
  },
}));

const fieldNames = [
  { label: 'file.name', id: 'node.title', field: 'FILENAME', direction: 'ASC' },
  { label: 'created.at', id: 'node.createdAt', field: 'CREATED_AT', direction: 'DESC', format: 'DATEFORMAT' },
];

const sortData = {
  default: { field: 'FILENAME', direction: 'ASC' },
};

export const FilesView = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const hospital = useSelector((state) => state.hospital);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);
  const dispatch = useDispatch();

  const { loading, data, error, refetch, fetchMore } = useQuery(
    GET_HOSPITAL_FILES_TO_LIST,
    {
      variables: {
        hospitalUuid: hospital.uuid,
        first: 50,
        orderBy: { field: orderByField, direction: orderByDirection },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const selectedFile = (data && data.hospitalFiles && selected.length > 0)
    && !!data.hospitalFiles.edges[selected[0]]
    && data.hospitalFiles.edges[selected[0]].node;

  const fetchMoreFiles = (fetchMoreCb) => {
    const { endCursor } = data.authorizedPatients.pageInfo;

    fetchMore({
      query: GET_HOSPITAL_FILES_TO_LIST,
      variables: {
        hospitalUuid: hospital.uuid,
        first: 50,
        cursor: endCursor,
        orderBy: { field: orderByField, direction: orderByDirection },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.hospitalFiles.edges;

        return newEdges.length ? {
          hospitalFiles: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.hospitalFiles.__typename,
            totalCount: previousResult.hospitalFiles.totalCount,
            pageInfo: fetchMoreResult.hospitalFiles.pageInfo,
            edges: [...previousResult.hospitalFiles.edges, ...newEdges],
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => (
        fetchMoreData.hospitalFiles.pageInfo.hasNextPage && fetchMoreCb()
      ));
  };

  const reverseDirection = () => (
    orderByDirection === 'ASC' ? 'DESC' : 'ASC'
  );

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch();
      setAnchorEl();
      setSelected([]);
    }
  };

  const getTitle = (item) => (
    `${item.node.fileName}`
  );

  const getSubTitle = (item) => (
    `${KBytesPrettify(item.node.fileSize).string}`
  );

  const handleView = (evt) => {
    evt.stopPropagation();
    const images = [selectedFile.file];

    dispatch({ type: 'SET_VIEWER_IMAGES', viewerImages: { viewerImages: images.map((image) => ({ src: image })) } });
    dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: true } });
  };

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexFile = data.hospitalFiles.edges[index].node;

    window.location = indexFile.file;
  };

  const handleDownload = (evt) => {
    handleGoto(evt, selected[0]);
  };

  const handleCloseDelete = (value) => {
    setShowDeleteModal(false);
    if (value) refetch();
  };

  const handleCloseUploadFile = (value) => {
    setShowUploadFileModal(false);
    if (value) refetch();
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hasImageExtension = (item) => imageExtensions.includes(getUrlExtension(item.file));

  const ImageFilesCollapse = ({ item }) => {
    const file = item.node ? item.node : item;

    return !!hasImageExtension(file) && (
      <Box className={styles.wrapper}>
        <Image styles={styles.image} src={file.file} alt={file.fileName} />
      </Box>
    );
  };

  const tableRowDownloadButton = {
    Icon: CloudDownload,
    tooltip: 'download',
  };

  const buttons = [
    { name: 'view', icon: ZoomOutMap, handleClick: handleView, disabled: !(selected.length === 1 && hasImageExtension(selectedFile)) },
    { name: 'divider1', type: 'divider' },
    { name: 'upload.file', icon: CloudUpload, handleClick: () => setShowUploadFileModal(true), disabled: false },
    { name: 'download', icon: CloudDownload, handleClick: handleDownload, disabled: selected.length === 0 },
    { name: 'delete', icon: Delete, handleClick: () => setShowDeleteModal(true), disabled: selected.length === 0 },
    { name: 'divider2', type: 'divider' },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder, disabled: false },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDown className={className} /> : <ArrowDropUp className={className} />
  );

  const errorMessage = networkErrorParse(error);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <ContainerUI>
      <Navbar>
        <DeleteFileDialog
          open={showDeleteModal}
          onClose={handleCloseDelete}
          file={selectedFile}
          uuid={hospital.uuid}
          type="hospitalFile"
        />
        {showUploadFileModal && (
          <UploadFileDialog
            open={showUploadFileModal}
            onClose={handleCloseUploadFile}
            uuid={hospital.uuid}
            type="hospitalFile"
            refetch={refetch}
          />
        )}
        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item, index) => (
            <MenuItem
              key={index.toString()}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>
        <SectionBar title="files" items={buttons} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <ScrollableContainer>
          <PageListContent>
            {(loading && isEmpty(data)) ? <Loading /> : (
              <SectionContainer>
                {
                  !!data.hospitalFiles && !!data.hospitalFiles.edges && data.hospitalFiles.edges.length
                    ? (
                      <TableCollapse
                        responsive={false}
                        fieldNames={fieldNames}
                        items={data.hospitalFiles.edges}
                        GetTitle={getTitle}
                        GetSubtitle={getSubTitle}
                        GetCollapse={ImageFilesCollapse}
                        selected={selected}
                        setSelected={setSelected}
                        handleGoto={handleGoto}
                        isMore={data
                          && data.hospitalFiles
                          && data.hospitalFiles.pageInfo
                          && data.hospitalFiles.pageInfo.hasNextPage}
                        fetchMoreFn={fetchMoreFiles}
                        order={{ field: orderByField, direction: orderByDirection }}
                        handleOrder={handleOrderBy}
                        rowActionButton={tableRowDownloadButton}
                      />
                    ) : <NoFilesWrapper>{t('no.files')}</NoFilesWrapper>
                }
              </SectionContainer>
            )}
          </PageListContent>
        </ScrollableContainer>
      </Container>
    </ContainerUI>
  );
};
